// export const url: string = 'http://192.168.0.234:5000';
// export const url: string = 'http://192.168.0.240:5000';
export const url: string = "https://server.atab.services";
// export const url: string = "http://192.168.0.168:5000"; //srabon
export const imgUrl: string =
  "https://m360ict.s3.ap-south-1.amazonaws.com/uploads";

// https://atab.blob.core.windows.net/atab-storage/uploads

//======== Auth context helper ========//
export const SET_SIGNUP_DATA = "SET_SIGNUP_DATA";
export const AUTH_USER_SUCCESS = "AUTH_USER_SUCCESS";
export const AUTH_USER_FAILED = "AUTH_USER_FAILED";
export const USER_PROFILE_UPDATE = "USER_PROFILE_UPDATE";
export const VISITOR_PROFILE_IMAGE_UPDATE = "VISITOR_PROFILE_IMAGE_UPDATE";
//======== visitor Auth context helper ========//

export const AUTH_VISITOR_USER_SUCCESS = "AUTH_VISITOR_USER_SUCCESS";
export const AUTH_VISITOR_USER_FAILED = "AUTH_VISITOR_USER_FAILED";
