import React from 'react';

const VisitorWishlist = () => {
  return (
    <>
      <div className='profile-right-side px-3 py-3'>
        <h4 className='component-tittle'>Wishlist</h4>
        <div className='profile-right-info pt-3 ps-2'>
          <p className='text-center fw-bold fs-4'>Under construction</p>
        </div>
      </div>
    </>
  );
};

export default VisitorWishlist;
